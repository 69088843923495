/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { List, Loader, Table } from '@fluentui/react-northstar';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setCallerIdOptions, transformToDialPlanOptions, transformToDropdownOptions } from '../../../helpers/utils';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { useQuery } from '../../../hooks/useQuery';
import { useGetUpdateUserStatusMutation, useUpdateUsersMutation } from '../../../redux/services/batchUpdateApi';
import { getCallerIds } from '../../../redux/services/callerIdApi';
import { CallerIdList } from '../../../types';
import VersionNumber from '../../VersionNumber';
import Dialog from '../../common/Dialog';
import Pagination from '../../common/Pagination';
import Context from '../context';
import ListData from './components/ListData';
import TableRow from './components/TableRow';
import { headerDetails } from './constants';
import { setFilterData } from './utils';

type ManageTableType = {
  theme: string | undefined;
};

const ManageTable = ({ theme }: ManageTableType): React.ReactElement => {
  const { token } = useSelector((state: RootState) => state.auth);
  const { isMobile } = useIsMobileView();
  const {
    filters,
    modifiedData,
    searchKey,
    currentPage,
    setCurrentPage,
    dialPlans,
    isDialPlanLoading,
    identities,
    getIdentities,
    isIdentitiesLoading,
    numbers,
    getNumbers,
    voiceRoutePolicies,
    isVoiceRouteLoading,
    setIsCompleted,
    setIsSaving,
    isCompleted,
    setIsMobileSuccess,
    isStateFreeze,
    setIsStateFreeze,
  } = useContext(Context);
  const { tenantData } = useSelector((s: RootState) => s.auth);
  const [identityData, setIdentityData] = useState<any>({});
  const [tableData, setTableData] = useState<any>({});
  const [cardData, setCardData] = useState<any>(null);
  const [identityFilter, setIdentityFilter] = useState([0, 1]);
  const [numberFilter, setNumberFilter] = useState([0, 1]);
  const [voiceRouteFilter, setVoiceRouteFilter] = useState([0, 1]);
  const [dialPlanFilter, setDialPlanFilter] = useState([0, 1]);
  const [licenseFilter, setLicenseFilter] = useState([0, 1]);
  const [filteredNumbers, setFilteredNumbers] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedUpn, setSelectedUpn] = useState<any>();
  const [filteredUsers, setFilteredUsers] = useState<any>([]);

  const [updateUsers] = useUpdateUsersMutation();
  const [, { data: updateUserStatusData }] = useGetUpdateUserStatusMutation();
  const { data: callerIds, loading: callerIdLoading } = useQuery<CallerIdList>(
    () => getCallerIds({ token, page: 1, limit: 9999 }),
    !!token,
  );

  const {
    identityType: { users, resource },
    number: { withNumber, withoutNumber },
    voiceRoute: { withVR, withoutVR },
    dialPlan: { withDP, withoutDP },
    license: { withLicense, withoutLicense },
  } = filters;

  const pageLimit = 10;

  useEffect(() => {
    let identity: any[] = [];
    if (users) identity = [...identity, 0];
    if (resource) identity = [...identity, 1];
    setIdentityFilter(identity);
  }, [users, resource]);

  useEffect(() => {
    if (identities && !numbers?.length) {
      getNumbers({ token });
    }
  }, [identities, getNumbers]);

  useEffect(() => {
    if (!modifiedData.data.length) {
      setFilteredNumbers(numbers);
    }
  }, [numbers, modifiedData]);

  useEffect(() => {
    let number: any[] = [];
    if (withoutNumber) number = [...number, 0];
    if (withNumber) number = [...number, 1];
    setNumberFilter(number);
  }, [withNumber, withoutNumber]);

  useEffect(() => {
    let voiceRoute: any[] = [];
    if (withoutVR) voiceRoute = [...voiceRoute, 0];
    if (withVR) voiceRoute = [...voiceRoute, 1];
    setVoiceRouteFilter(voiceRoute);
  }, [withVR, withoutVR]);

  useEffect(() => {
    if (updateUserStatusData?.status === 1) {
      setIsDialogOpen(false);
    }
  }, [updateUserStatusData]);

  useEffect(() => {
    let dialPlan: any[] = [];
    if (withoutDP) dialPlan = [...dialPlan, 0];
    if (withDP) dialPlan = [...dialPlan, 1];
    setDialPlanFilter(dialPlan);
  }, [withDP, withoutDP]);

  useEffect(() => {
    let license: any[] = [];
    if (withoutLicense) license = [...license, 0];
    if (withLicense) license = [...license, 1];
    setLicenseFilter(license);
  }, [withLicense, withoutLicense]);

  useEffect(() => {
    if (!token) return;
    const body = {
      page: 1,
      limit: pageLimit,
      search: searchKey,
      identityFilter,
      numberFilter,
      voiceRouteFilter,
      dialPlanFilter,
      licenseFilter,
    };
    getIdentities({ token, body }).then((res: any) => {
      const value = res?.data?.data ?? [];
      setCardData({ ...identities, data: value });
    });
  }, [dialPlanFilter, identityFilter, licenseFilter, numberFilter, searchKey, voiceRouteFilter]);

  useEffect(() => {
    setIdentityData(identities);
    setIsStateFreeze(false);
  }, [identities]);

  useEffect(() => {
    if (identityData?.data && !isStateFreeze) {
      const data = setFilterData(identityData, filters);
      const users = data.map((user) => user.userPrincipalName);

      setFilteredUsers(users);
      setTableData({ ...identities, data });

      if (!cardData) {
        setCardData({ ...identities, data });
      }

      setIsStateFreeze(true);
    }
  }, [filters, identities, identityData]);

  useEffect(() => {
    const dirtyData = modifiedData.data;
    if (identities && dirtyData.length > 0) {
      let data = [...identities.data];
      let withError = false;

      data = data.map((item: any, index: number) => {
        let identity = { ...item };

        const userInfo = dirtyData.find(
          ({ userPrincipalName }: { userPrincipalName: string }) => userPrincipalName === identity.userPrincipalName,
        );

        if (userInfo) {
          const { onlineVoiceRoutingPolicy, callerIdPolicy, onPremLineURI, tenantDialPlan } = userInfo;

          identity = {
            ...data[index],
            onPremLineURI: onPremLineURI,
            tenantDialPlan: tenantDialPlan,
            onlineVoiceRoutingPolicy: onlineVoiceRoutingPolicy,
            callerIdPolicy: callerIdPolicy,
            dirtyProps: userInfo?.dirtyProps ?? [],
            errors: userInfo?.errors ?? [],
            status: userInfo?.status ?? 0,
          };

          if (userInfo?.errors) {
            setSelectedUpn({ ...selectedUpn, errors: userInfo?.errors });
            withError = true;
          }
        }

        return identity;
      });

      const filteredData = isStateFreeze
        ? data.filter(({ userPrincipalName }) => filteredUsers.includes(userPrincipalName))
        : data;

      setIdentityData({ ...identities, data: filteredData });
      setTableData({ ...identities, data: filteredData });

      if (!withError && isCompleted) {
        setIsMobileSuccess(true);
        setCardData({ ...identities, data: filteredData });
      }
    }
  }, [modifiedData, identities]);

  useEffect(() => {
    const dirtyData = modifiedData.data;

    if (identities && dirtyData.length === 0) {
      const data = [...(identities?.data ?? [])];

      setIdentityData({ ...identities, data });
      setTableData({ ...identities, data });
    }
  }, [identities, modifiedData]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    const body = {
      page,
      limit: pageLimit,
      search: searchKey,
      identityFilter,
      numberFilter,
      voiceRouteFilter,
      dialPlanFilter,
      licenseFilter,
    };
    getIdentities({ token, body }).then((res: any) => {
      const value = res?.data?.data ?? [];
      setCardData({ ...identities, data: value });
    });
  };

  const getFilteredNumbers = () => {
    let selectedNumbers: any = [];

    identityData?.data?.forEach((item: any) => {
      if (item.onPremLineURI) {
        selectedNumbers = [...selectedNumbers, item.onPremLineURI];
      }
    });

    modifiedData?.data?.forEach((item: any) => {
      if (item.onPremLineURI) {
        selectedNumbers = [...selectedNumbers, item.onPremLineURI];
      }
    });

    return filteredNumbers?.filter((item: any) => !selectedNumbers.includes(item.number) && !item.isAssigned);
  };

  const handleOpenUpn = (item: any) => {
    setSelectedUpn(item);
    setIsDialogOpen((prev) => !prev);
  };

  const handleCancelUpn = () => {
    setIsMobileSuccess(false);
    setSelectedUpn(null);
    setIsDialogOpen(false);
    setIsCompleted(false);
  };

  const handleSaveChanges = (upn: any) => {
    updateUsers({ body: { data: [upn] }, token })
      .unwrap()
      .then((res: any) => {
        sessionStorage.setItem('jobId', res?.id);
      });
    setIsCompleted(false);
    setIsSaving(true);
  };

  const isLoading = isIdentitiesLoading || isDialPlanLoading || isVoiceRouteLoading || callerIdLoading;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isMobile ? (
            <List selectable items={ListData(cardData, handleOpenUpn)} className="user-list" />
          ) : (
            <Table
              className="users-table"
              header={headerDetails}
              rows={TableRow(
                identities,
                tableData,
                theme,
                identityData,
                setFilteredNumbers,
                filteredNumbers,
                voiceRoutePolicies,
                dialPlans,
                setIdentityData,
                callerIds?.data,
                tenantData?.serviceType,
              )}
            />
          )}
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={identityData?.totalCount ?? 0}
            pageSize={pageLimit}
            onPageChange={onPageChange}
          >
            <VersionNumber />
          </Pagination>
          <Dialog
            isOpen={isDialogOpen}
            onCancel={handleCancelUpn}
            onConfirm={handleSaveChanges}
            selectedUpn={selectedUpn}
            callerIdPolicies={setCallerIdOptions(callerIds?.data)}
            dialPlans={transformToDialPlanOptions(dialPlans)}
            numbers={transformToDropdownOptions(getFilteredNumbers())}
            voiceRoutes={transformToDropdownOptions(voiceRoutePolicies)}
          />
        </>
      )}
    </>
  );
};

export default ManageTable;
