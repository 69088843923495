import { PropsWithChildren, useContext } from 'react';
import { Redirect, Route, RouteProps, HashRouter as Router, Switch } from 'react-router-dom';
import { BYPASS_PERMISSIONS_CHECK } from '../authConfig';
import { AppContext } from '../contexts/AppContext';
import { AuthContext } from '../contexts/AuthContext';
import CallerIdPage from './CallerId';
import Forbidden from './Forbidden';
import Login from './Login';
import Manage from './Manage';
import Onboarding from './Onboarding';
import Partners from './Partner/Partners';
import Setting from './Setting';
import Setup from './Setup';
import AppHeader from './common/AppHeader';
import { Loader } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

type GuardRouteProps = {
  component: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type RouterContainerProps = {
  isInTeams?: boolean;
};

const GuardRoute = (props: GuardRouteProps & RouteProps) => {
  const { isPermissionsCheckComplete } = useContext(AppContext);
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={() =>
        isPermissionsCheckComplete || BYPASS_PERMISSIONS_CHECK ? <Component {...rest} /> : <Redirect to="/" />
      }
    />
  );
};

const AuthRoute = (props: GuardRouteProps & RouteProps) => {
  const { isInTeams } = useContext(AppContext);
  const { token, tenant } = useSelector((state: RootState) => state.auth);
  const { isPermitted, busy } = useContext(AuthContext);
  const { component: Component, ...rest } = props;

  const renderIfPermitted = (children: React.ReactNode) => {
    if (isPermitted) return children;
    return <Route {...rest} render={() => <Redirect to="/forbidden" />} />;
  };

  return (
    <>
      {busy ? (
        <Loader style={{ margin: 100 }} />
      ) : (
        <>
          {token || isInTeams ? (
            renderIfPermitted(
              <Route {...rest} render={() => (tenant ? <Component {...rest} /> : <Redirect to="/partner" />)} />,
            )
          ) : (
            <Route {...rest} render={() => <Redirect to="/login" />} />
          )}
        </>
      )}
    </>
  );
};

const RouteContainer = (props: PropsWithChildren<RouterContainerProps>): React.ReactElement => {
  if (props.isInTeams) return <>{props.children}</>;
  return (
    <Router>
      <AppHeader />
      <div style={{ padding: 16 }}>{props.children}</div>
    </Router>
  );
};

const Routes = (): React.ReactElement => {
  const { isInTeams } = useContext(AppContext);
  return (
    <RouteContainer isInTeams={isInTeams}>
      <Router>
        <Switch>
          <AuthRoute exact path="/" component={Onboarding} />
          <Route exact path="/forbidden" component={Forbidden} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/partner" component={Partners} />
          <GuardRoute exact path="/tab" component={Manage} />
          <GuardRoute exact path="/setup" component={Setup} />
          <GuardRoute exact path="/callerId" component={CallerIdPage} />
          <GuardRoute exact path="/setting" component={Setting} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </RouteContainer>
  );
};

export default Routes;
