/* eslint-disable @typescript-eslint/no-explicit-any */
import { loadConfiguration, ResourceType, LogLevel, setLogLevel, setLogFunction } from '@microsoft/teamsfx';
import { useData } from './useData';
import { useTeams } from 'msteams-react-base-component';
import { ThemePrepared } from '@fluentui/react-northstar';

const teamsfxEndpoint = process.env.REACT_APP_TEAMSFX_ENDPOINT;
const startLoginPageUrl = process.env.REACT_APP_START_LOGIN_PAGE_URL;
const functionEndpoint = process.env.REACT_APP_FUNC_ENDPOINT;
const clientId = process.env.REACT_APP_CLIENT_ID;

// TODO fix this when the SDK stops hiding global state!
let initialized = false;

type TeamsFxType = {
  isInTeams?: boolean;
  fullScreen?: boolean;
  theme: ThemePrepared;
  themeString: string;
  loading: boolean;
  context?: microsoftTeams.Context;
  error?: any;
};

export function useTeamsFx(): TeamsFxType {
  const [result] = useTeams({});
  const { error, loading } = useData(async () => {
    if (!initialized) {
      if (process.env.NODE_ENV === 'development') {
        setLogLevel(LogLevel.Verbose);
        setLogFunction((leve: LogLevel, message: string) => {
          console.log(message);
        });
      }
      if (result.inTeams) {
        loadConfiguration({
          authentication: {
            initiateLoginEndpoint: startLoginPageUrl,
            simpleAuthEndpoint: teamsfxEndpoint,
            clientId: clientId,
          },
          resources: [
            {
              type: ResourceType.API,
              name: 'default',
              properties: {
                endpoint: functionEndpoint,
              },
            },
          ],
        });
      }
      initialized = true;
    }
  });
  return { error, loading, isInTeams: result.inTeams, ...result };
}
