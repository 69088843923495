/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { FilesUploadIcon, UndoIcon } from '@fluentui/react-icons-northstar';
import { Alert, Button, Flex, Loader, Toolbar } from '@fluentui/react-northstar';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetBannersMutation } from 'redux/services/bannerApi';
import { RootState } from 'redux/store';
import { ConfigChanges } from 'types';
import { useInterval } from '../../../hooks/useInterval';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { useGetUpdateUserStatusMutation, useUpdateUsersMutation } from '../../../redux/services/batchUpdateApi';
import Context from '../context';
import ManageCarousel from './ManageCarousel';
import { toolbarStyles } from './ManagePublishToolbar.styles';

type statusType = { status: number };

const ManagePublishToolbar = (): React.ReactElement => {
  const [updateUsers, { data: updateUsersData }] = useUpdateUsersMutation();
  const [getUpdateUserStatus, { data: updateUserStatusData }] = useGetUpdateUserStatusMutation();
  const [getBanners, { data: banners, isLoading: isGetBannersLoading }] = useGetBannersMutation();
  const { isMobile } = useIsMobileView();
  const { modifiedData, setModifiedData, isSaving, setIsSaving, isCompleted, setIsCompleted, compareResults } =
    useContext(Context);
  const { token } = useSelector((state: RootState) => state.auth);
  const [isVisible, setIsVisible] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);
  const [successCount, setSucessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);

  useEffect(() => {
    if (sessionStorage.getItem('jobId')) {
      setIsSaving(true);
    }
  }, [setIsSaving]);

  useEffect(() => {
    let changes = 0,
      success = 0,
      error = 0;
    ({ error, success, changes } = setCount(modifiedData, error, success, changes));

    setUpdateCount(changes);
    setSucessCount(success);
    setErrorCount(error);
  }, [modifiedData]);

  useEffect(() => {
    getBanners({ token });
  }, []);

  const setCount = (modifiedData: any, error: number, success: number, changes: number) => {
    if (!modifiedData?.data.length) return { error, success, changes };

    modifiedData.data.forEach(({ status }: statusType) => {
      switch (status) {
        case 0:
          changes += 1;
          break;
        case 1:
          success += 1;
          break;
        default:
          error += 1;
          break;
      }
    });

    return { error, success, changes };
  };

  const updateUserInfo = (userStatusData?: any) => {
    const statusData = (userStatusData?.result || userStatusData?.results) ?? updateUserStatusData?.results;

    if (statusData) {
      let data = [...modifiedData.data];

      if (!modifiedData.data.length && statusData) {
        data = statusData.map((e: any) => e.data);
      }

      statusData.forEach((userStatus: any, i: number) => {
        let index = i;

        if (data.length) {
          index = data.findIndex(
            ({ userPrincipalName }: { userPrincipalName: string }) =>
              userPrincipalName === userStatus.data.userPrincipalName,
          );
        }

        if (index >= 0) {
          data[index] = {
            ...data[index],
            errors: userStatus.errors?.map((e: { message: string }) => e.message),
            status: userStatus.status,
          };

          setModifiedData({ data });
        }
      });
    }
  };

  const withChanges = useMemo(() => {
    return updateCount !== 0;
  }, [updateCount]);

  const alertContent = useMemo(() => {
    if (!updateCount && !successCount && !errorCount) {
      return 'Loading...';
    } else if (isSaving || (!withChanges && isCompleted)) {
      return `Status: ${updateCount} remaining, ${successCount} success, ${errorCount} errored`;
    }
    return `${updateCount} rows updated`;
  }, [updateCount, successCount, errorCount, isSaving, withChanges, isCompleted]);

  const compareResultText = useMemo(() => {
    if (!compareResults) return null;
    const { voiceRoutes, dialPlans, pstns, domain } = compareResults as ConfigChanges;
    let result = '';
    if (voiceRoutes) result += 'Voice routes has changed, ';
    if (dialPlans) result += 'Dial plans has changed, ';
    if (pstns) result += 'PSTNs has changed, ';
    if (domain) result += 'Domain has changed, ';
    return result.substring(0, result.length - 2);
  }, [compareResults]);

  useInterval(() => {
    if (isCompleted) return;

    const localJobId = sessionStorage.getItem('jobId');

    const { id } = updateUsersData || {};

    const jobId = id || localJobId;

    const isIdValid = jobId === updateUserStatusData?.id;

    if (jobId && (!updateUserStatusData || updateUserStatusData.status === 0 || !isIdValid)) {
      getUpdateUserStatus({ jobId, token })
        .unwrap()
        .then((res: any) => {
          if (!res) {
            resetState();
          } else {
            updateUserInfo(res);
          }
        });

      if (!localJobId) sessionStorage.setItem('jobId', id);
      if (!isSaving) setIsSaving(true);
    } else {
      if (updateUserStatusData && isIdValid) {
        resetState();
        setIsVisible(true);
      }
    }

    updateUserInfo();
  }, 4000);

  const getAllPendingStatus = () => modifiedData?.data?.filter(({ status }: statusType) => status === 0);

  const handleSaveChanges = () => {
    const filteredData = modifiedData.data.filter(({ status }: statusType) => status === 0);

    updateUsers({ body: { data: filteredData }, token }).unwrap();

    setIsCompleted(false);
    setIsSaving(true);
    setIsVisible(true);
  };

  const resetState = () => {
    getUpdateUserStatus({ jobId: 0, token: undefined }).unwrap();
    updateUsers({ body: { data: null }, token: null }).unwrap();
    setIsCompleted(true);
    setIsSaving(false);
    sessionStorage.removeItem('jobId');
  };

  const isDisabled = !getAllPendingStatus().length || isSaving;

  if (isMobile) return <></>;

  return (
    <>
      <div style={{ flexGrow: 1 }}>
        {!isGetBannersLoading && <ManageCarousel banners={banners?.filter((e: any) => e.isHeaderBanner)} />}
      </div>
      <Flex fill space="between" className="publish-toolbar">
        {!compareResultText ? (
          <span></span>
        ) : (
          <Alert
            dismissAction={{ 'aria-label': 'close' }}
            visible={!!compareResultText}
            content={compareResultText}
            data-testid="alert-status"
            style={{ height: '32px' }}
          />
        )}
        <>
          {updateCount > 0 && (
            <Alert
              dismissible={updateCount === 0}
              onVisibleChange={() => setIsVisible(false)}
              dismissAction={{ 'aria-label': 'close' }}
              visible={withChanges || isVisible || isSaving || Object.keys(compareResults)?.length > 0}
              content={alertContent}
              data-testid="alert-status"
              style={{ height: '32px' }}
            />
          )}
          <Toolbar
            style={toolbarStyles}
            aria-label="Default"
            items={[
              {
                content: (
                  <Button
                    content="Discard Changes"
                    disabled={isDisabled}
                    icon={<UndoIcon />}
                    onClick={() => setModifiedData({ data: [] })}
                    data-testid="btn-discard-changes"
                    text
                  />
                ),
                key: 'discard',
                kind: 'custom',
              },
              {
                key: 'divider-3',
                kind: 'divider',
              },
              {
                content: (
                  <Button
                    content="Save Changes"
                    disabled={isDisabled}
                    icon={isSaving ? <Loader size="smallest" /> : <FilesUploadIcon />}
                    onClick={handleSaveChanges}
                    data-testid="btn-save-changes"
                    text
                  />
                ),
                key: 'save',
                kind: 'custom',
              },
            ]}
          />
        </>
      </Flex>
    </>
  );
};

export default ManagePublishToolbar;
