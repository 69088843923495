import { Box, Flex, Header, Loader, Text } from '@fluentui/react-northstar';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';

const Forbidden = (): React.ReactElement => {
  const { token } = useSelector((state: RootState) => state.auth);
  const { isPermitted, busy } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (busy) return;
    if (!token) return;
    if (isPermitted) history.push('/');
  }, [busy, token, isPermitted]);

  return (
    <Flex
      hAlign="center"
      vAlign="center"
      style={{ height: '100vh', backgroundColor: '#f6f6f6', margin: '-0.5rem -1.5rem' }}
    >
      {busy ? (
        <Loader style={{ margin: 100 }} />
      ) : (
        <Flex key="center" hAlign="center" vAlign="center" style={{ width: 350, padding: 20, textAlign: 'center' }}>
          <Box>
            <Header color="brand" as="h1" content="Forbidden" />
            <Text
              size="large"
              content="You are not allowed to access this application. Please contact your administrator to request access."
            />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default Forbidden;
